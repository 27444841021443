<template>
<div>
  <Loading :isLoading="isLoading" />
  <div>
    <p style="padding:10px 10px 0px 10px;font-size:20px;color:#810042;font-weight:700;font-family:Lato;">Pago parte social</p>
  </div>
  
  <div class="py-2">
    <TableComponent :key="render" :columns="columns" :rows="rows" :rangeInput="true" :exportInput="true"/>
  </div>
</div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import Loading from '@/components/Loading/VueLoading.vue';

import TableComponent from "@/components/Forms/Tables/TableComponent";
import {getTokenDecoden} from "@/helpers/tokenauth";
import Affiliated from '@/classes/Affiliated.js';
import AdmissionRequest from "@/classes/AdmissionRequest";

import { useRouter } from 'vue-router';

export default {
  name: 'SolicitudesEnProceso',
  setup(){
    const isLoading = ref(true);
    const apiToken = ref("");
    const personId = ref("");
    const requests = ref([]);
    const requestType = ref("");
    const associates = ref([]);
    const userId = ref(0);
    const assignmentType = ref("Reasignar");
    const affiliatedObject = ref(null);
    const admissionObject = ref(null);
    const columns = ref([]);
    const rows = ref([]);
    const router = useRouter();

    let app = getCurrentInstance();
    let render = ref(false);

    onMounted(async () => {
      app = app.appContext.config.globalProperties;
      affiliatedObject.value = new Affiliated();
      admissionObject.value = new AdmissionRequest();
      loadInfo();
      await getAssociates();
      await getRequests();
      isLoading.value = false;
    });

    const assign = async (idRequest) => {
      var swal = require('sweetalert2');
      isLoading.value = true;

      await admissionObject.value.assingAnalystLogged(apiToken.value, personId.value, idRequest).then(() => {
        getRequests().then(() => {
          swal.fire({
            title: "Aviso",
            text: "La solicitud fue asignada a un asociado correctamente",
            icon: "success",
            confirmButtonColor: '#FEB72B',
          });
          isLoading.value = false;
        });
      }).catch(err => {
        console.log("Error", err);
        isLoading.value = false;
      });
    }

    const getRequests = async() => {
      render.value = false;
      await admissionObject.value.getListPaymentsAndAdmissionRequest(apiToken.value).then((response) => {
        console.log(response);
        let fields = getFinalArrayList(response.data.response.data);
        getColumns(); 
        rows.value = fields.reverse();
             
        render.value = true;
        isLoading.value = false;
      });
    }

    const getAssociates = async () =>{
      affiliatedObject.value.getAllAnalysts(apiToken.value).then(response => {
        associates.value = response.data.response.analysts;
      });
    }

    const getColumns = () => {
      columns.value = [
        { headerName: "RFC", field: "rfc"},
        { headerName: "Nombre", field: "name" },
        { headerName: "Teléfono", field: "phone" },
        { headerName: "Correo", field: "email" },
        { headerName: "Fecha de pago", field: "dateEnrollPaymentFee" },
        { headerName: "Metodo de pago", field: "method_payment" },
        { headerName: "Acciones", field: "action", filter: false, cellRenderer: params => {
          console.log("Params", params);
          const input = document.createElement("label");
          input.innerText = "Visualizar Recibo";
          input.className += "bg-cherryColor text-white focus:bg-blue-700 px-10 py-1.5 mx-auto rounded-md cursor-pointer text-base";
          input.addEventListener("click", e => {
            console.log(params);
            e.preventDefault();
            showReceipt(params.value);
          });
          return input;
        }}
      ];
    }

    const viewRequest = (value) => {
      router.push({path: value})
    }

    const loadInfo = () => {
      var auth = getTokenDecoden();
      apiToken.value = sessionStorage.getItem("login");
      personId.value  = auth.obj.personnel_info['id'];
      userId.value  = auth['id'];
    }
    
    const getFinalArrayList = (array) => {
      let finalArray = [];
      
      for (let i = 0; i < array.length; i++) {
        finalArray.push({
          user_id: array[i].user_id,
          person_id: array[i].person_id,
          admission_request_id: array[i].admission_request_id,
          status: array[i].status,
          payment_id: array[i].payment_id,
          rfc: array[i].rfc,
          name: app.$filters.formatCamelize(array[i].name),
          middle_name: app.$filters.formatCamelize(array[i].middle_name),
          paternal_name: app.$filters.formatCamelize(array[i].paternal_name),
          maternal_name: app.$filters.formatCamelize(array[i].maternal_name),
          phone: app.$filters.formatPhone(array[i].phone),
          email: array[i].email,
          date_payment: app.$filters.formatDate(array[i].date_payment),
          method_payment: array[i].method_payment,
          has_receipt_enrollment_payment_fee: array[i].has_receipt_enrollment_payment_fee,
          action: array[i].admission_request_id
      })
      }
      return finalArray;
    }

    const convertListToArray = (list) => {
      let index = [];
      let array = [];
      for (var i in list) {
        index.push(i);
      }

      for (let i = 0; i < index.length; i++) {
        array.push(list[index[i]]);
      }

      return array;
    }

    const getAsignedUser = (value) => {
      var asociate = associates.value.filter((associate) => associate.id == value)
      if(asociate.length > 0){
        return `${asociate[0].name.toUpperCase()} ${asociate[0].paternal_name.toUpperCase()}`
      }else{
        return ""
      }
    }

    const showReceipt = (admission_request_id) => {
      var Swal = require('sweetalert2');
      isLoading.value = true;
      admissionObject.value.getReceipt(apiToken.value, "documentos_alta_socios", admission_request_id).then(response => {
        isLoading.value = false;
        window.open(response.data.response.link);
      }).catch(err => {
        isLoading.value = false;
        Swal.fire({
          title: "Aviso",
          text: err.response.data.errors[0],
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
      });
    }


    return {
      isLoading,
      apiToken,
      personId,
      requests,
      requestType,
      associates,
      userId,
      assignmentType,
      columns,
      rows,
      render,
      viewRequest
    }
  },
  components: {
    Loading,
    TableComponent
  },
}

</script>